import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { StylesProps } from './FormSelect.types';

export const useStyles = makeStyles<Theme, StylesProps>(
  ({ palette, spacing, typography: { fontWeightBold, fontWeightRegular } }) => ({
    label: {
      fontSize: spacing(2),
      lineHeight: 1,
      fontWeight: ({ boldLabel }) => (boldLabel ? fontWeightBold : fontWeightRegular),
      margin: spacing(0, 0, 1, 0),
    },
    helperText: {
      display: 'flex',
      fontSize: spacing(2),
      color: ({ error, success }) => (error ? palette.error.main : success ? palette.success.main : palette.gray.main),
      lineHeight: spacing(3),
      margin: spacing(1, 0, 0, 0),
    },
    helperIcon: {
      display: 'flex',
      alignItems: 'center',
      margin: spacing(0, 1, 0, 0),
    },
    checkIcon: {
      color: palette.success.main,
    },
  }),
);
