import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ spacing, typography: { fontWeightBold } }) => ({
  wrapper: {
    display: 'flex',
  },
  status: {
    height: spacing(4),
    padding: spacing(0.625, 2.25),
    borderRadius: spacing(3),
    fontWeight: fontWeightBold,
  },
}));
