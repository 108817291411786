import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(
  ({
    breakpoints,
    spacing,
    typography: { body1, fontWeightBold },
    palette: { white, darkGray, black, primary, lightBlue },
  }) => ({
    root: {
      width: '100%',
      background: white.main,
      padding: spacing(2.5, 4, 2.5, 2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: spacing(1.5),
      boxShadow: `0 ${spacing(2)} ${spacing(3)} 0 rgba(48, 49, 51, 0.1), 0 0 ${spacing(
        0.125,
      )} 0 rgba(48, 49, 51, 0.05)`,
      cursor: 'pointer',
      border: spacing(0.25, 'solid', 'transparent'),
      transition: 'all .3s ease-out',
      [breakpoints.down('lg')]: {
        height: '100%',
      },
      '& svg': {
        width: '100%',
        fill: `${primary.main} !important`,
      },
      '&:hover': {
        border: spacing(0.25, 'solid', primary.main),
        background: lightBlue.light,
      },
      '&.is-active': {
        border: spacing(0.25, 'solid', primary.main),
        background: lightBlue.light,
      },
    },
    startAdornment: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: spacing('0', '0', 6.5),
    },
    content: {
      padding: spacing(0, 3),
      fontSize: body1.fontSize,
      lineHeight: body1.lineHeight,
    },
    title: {
      fontWeight: fontWeightBold,
      color: black.main,
    },
    subtitle: {
      color: darkGray.main,
    },
  }),
);
