import { MutationConfig, QueryConfig, useMutation, useQuery } from 'react-query';

import { CalculateTaxByAvalaraProps, CalculateTaxByAvalaraResponse, GetCheckoutData, GetRetailDetailsResponse, UpdateCheckout } from './checkout.types';

export const updatePaymentDetails = ({ client, body, paymentId }: UpdateCheckout) =>
  client.patch(`checkout/${paymentId}`, body).then(({ data }) => data);

export const useUpdatePaymentDetails = (
  options?: MutationConfig<UpdateCheckout, Record<'data', Record<'stack' | 'message', ''>>>,
) => useMutation(updatePaymentDetails, options);

const getRetailDetails = ({ client, paymentId }: GetCheckoutData) =>
  client.get<GetRetailDetailsResponse>(`/retail/${paymentId}`).then(({ data }) => data);

export const useGetRetailDetails = ({
  client,
  paymentId,
  options,
}: GetCheckoutData & {
  options?: QueryConfig<GetRetailDetailsResponse, unknown> | undefined;
}) =>
  useQuery(['getRetailDetails', paymentId], () => getRetailDetails({ client, paymentId }), {
    ...options,
  });

  export const calculateTaxByAvalara = ({ client, body }: CalculateTaxByAvalaraProps) =>
  client.post<CalculateTaxByAvalaraResponse>('herffjones', body).then(({ data }) => data);