import { postActivity } from 'api/activityTrack/activityTrack';
import { ActivityCodes, ActivityTypes } from 'api/activityTrack/activityTrack.types';
import { useActivityTrackClient } from 'hooks/useActivityTrackClient/useActivityTrackClient';

export const useActivityTrack = () => {
  const activityTrackClient = useActivityTrackClient();

  const onActivityTrack = async (
    activityType: ActivityTypes,
    activityCode: ActivityCodes,
    institutionId: string,
    description?: string,
    email?: string,
    participantId?: string,
    data?: string,
  ) => {
    const institution: string[] = [institutionId];

    const body = {
      activityType: activityType,
      activityCode: activityCode,
      email: email || '',
      participantId: participantId,
      channel: 'CHECKOUT',
      institution,
      userName: '',
      data: {
        description: description,
        data,
      },
    };

    return postActivity({ activityTrackClient, body });
  };

  return { onActivityTrack };
};
