import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ palette: { black, gray }, breakpoints, spacing }) => ({
  container: {
    color: black.main,
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  paymentDetails: {
    width: '50vw',
    [breakpoints.down('md')]: {
      width: '100vw',
      padding: spacing(0, 2.5, 2, 2.5),
    },
  },
  productDetails: {
    width: '50vw',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    padding: '7vh 0px 0px 0px',
    [breakpoints.down('md')]: {
      width: '100%',
      padding: spacing(2, 2.5, 0, 2.5),
      gap: spacing(1),
    },
  },
  backButton: {
    display: 'flex',
    gap: '10px',
    color: `${black.main} !important`,
  },
  productsHeader: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold !important',
  },
  productDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    margin: spacing(0, 'auto'),
    [breakpoints.down('md')]: {
      width: '100%',
      paddingBottom: spacing(2),
    },
  },
  paymentCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  paypalCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  paymentElement: {
    width: '60%',
    padding: '0px 0px 60px 0px',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  paymentButton: {
    width: '60%',
    fontWeight: '500 !important',
    fontSize: '18px !important',
    padding: '10px 0px !important',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  taxButton: {
    width: '100%',
    fontWeight: '500 !important',
    fontSize: '18px !important',
    padding: '10px 0px !important',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  totalPayContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    margin: spacing(0, 'auto'),
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  totalPayHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600 !important',
    gap: spacing(1),
  },
  totalPayText: {
    fontWeight: '600 !important',
  },
  taxPayText: {
    fontWeight: '500 !important',
  },
  taxValueText: {
    fontWeight: '400 !important',
    color: gray.contrastText,
  },
  priceContainer:{
    display:'flex',
   justifyContent:'space-between',
   alignItems:'center'
  },
  taxContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: spacing(2),
    padding: spacing(0, 2.5),
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing(0),
  },
  instituteLogo:{
    width:'200px',
    height:'auto',
    objectFit:'cover',
  },
  instituteLogoContainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  institutelogoTag:{
    width:'350px',
    height:'150px',
    objectFit:'contain'
  },
  googleImageLogo: {
    height: '20px',
    paddingRight: spacing(0.5),
  },
  imageLogo: {
    height: '20px',
    filter: 'invert(100%)',
    paddingRight: spacing(0.5),
  },
  payByButton: {
    width: '60%',
    padding: '5px !important',
    backgroundColor: `${black.main} !important`,
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  orText: {
    padding: '10px 0px',
    color: gray.dark,
  },
  orTextTwo: {
    padding: '30px 0px',
    color: gray.dark,
  },
  termsConditionLargeContainer: {
    width: '60%',
    display: 'flex',
    margin: 'auto auto 10vh auto',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  poweredByTextContainer: {
    display: 'flex',
    color: gray.dark,
  },
  stageClipText: {
    fontWeight: '600 !important',
    paddingLeft: '5px',
  },
  divider: {
    height: '80%',
    width: '1px',
    backgroundColor: gray.light,
  },
  backButtonContainer: {
    width: '70%',
    margin: '0px auto',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  termsConditionMobileContainer: {
    width: '100%',
    display: 'none',
    margin: '10vh auto auto  auto',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    [breakpoints.down('md')]: {
      display: 'flex',
    },
  },
}));
