import { PaletteOptions } from '@mui/material/styles/createPalette';

const blue = '#227b84';
const white = '#fff';
const gray = '#e6e6e6';
const green = '#18a957';
const dodgerBlue = '#4688f1';
const error = '#df1642';
const errorDark = '#a71031';
const grayDark = '#939393';
const black = '#212121';
const teal = '#14555c';
const moderateCyan = '#52bbc7';
const powderBlue = '#a9dde3';
const aquaSpring = '#e9f6f8';
const darkGray = '#757575';
const scorpion = '#595959';
const lightGray = '#cfcfcf';
const borderGray = '#cfcfcf';
const errorLight = '#FCE8EC';
const calyps = '#2d6187';
const darkGreen = '#0a4423';
const lightGreen = '#40ddc8';
const errorLighter = '#e95c7b';
const errorLighest = '#f9d0d9';
const orange = '#ffbb38';
const violet = '#5540fb';
const successLight = '#5dc389';
const alabaster = '#fafafa';
const doveGray = '#666';
const shark = '#303133';
const jellyBean = '#11763D';
const primaryLighter = '#90bdc1';
const primaryLightest = '#d2e4e6';
const lightGrayFill = '#fcfcfc';
const alto = '#d8d8d8';
const dustyGray = '#979797';
const lighterBlack = '#404042';
const ballBlue = '#28abb9';
const greenishBlue = '#227b84';
const greenLighter = '#d1eedd';

export const palette: PaletteOptions = {
  primary: {
    dark: darkGreen,
    main: green,
    contrastText: white,
    light: aquaSpring,
  },
  secondary: {
    main: successLight,
  },
  gray: {
    main: gray,
    dark: grayDark,
    light: lightGray,
  },
  white: {
    main: white,
  },
  success: { main: green, light: successLight },
  focus: { main: green },
  error: { main: error, light: errorLight, dark: errorDark },
  black: { main: black, light: lighterBlack },
  action: { active: darkGreen, hover: successLight, disabledBackground: gray },
  text: { primary: black, secondary: grayDark },
  lightGray: {
    main: alabaster,
    light: lightGrayFill,
  },
  darkGray: {
    main: darkGray,
    dark: scorpion,
  },
  gradient: {
    main: 'linear-gradient(to right, #163043, #0D1C28)',
  },
  lightBlue: {
    main: powderBlue,
    light: aquaSpring,
    dark: primaryLighter,
  },
  borderGray: {
    main: borderGray,
  },
  blue: {
    main: calyps,
    light: primaryLightest,
    dark: blue,
    contrastText: teal,
  },
  green: {
    main: green,
    dark: darkGreen,
    light: lightGreen,
    contrastText: greenLighter,
  },
  errorLight: {
    main: errorLighter,
    light: errorLighest,
  },
  orange: {
    main: orange,
  },
  violet: {
    main: violet,
  },
  doveGray: {
    main: doveGray,
    dark: shark,
  },
  jellyBean: {
    main: jellyBean,
  },
  alto: {
    main: alto,
  },
  dustyGray: {
    main: dustyGray,
  },
  greenishBlue: {
    dark: greenishBlue,
    main: ballBlue,
    light: dodgerBlue,
    contrastText: moderateCyan,
  },
};
