import { AxiosInstance } from 'axios';
import { ProviderProps } from 'react';

import { ClientContext, ClientDispatchContext } from '../clientContext/clientContext';
import { ClientDispatchContextType } from '../clientContext/clientContext.types';

export const ClientContextProvider = ({ children, value }: ProviderProps<AxiosInstance>) => (
  <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
);

export const ClientDispatchProvider = ({ children, value }: ProviderProps<ClientDispatchContextType>) => (
  <ClientDispatchContext.Provider value={value}>{children}</ClientDispatchContext.Provider>
);
