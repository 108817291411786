import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ breakpoints, spacing, palette: { success, white } }) => ({
  root: {
    '&.Toastify__toast-container': {
      width: 'unset',
      padding: 0,
      top: spacing(10),
      right: spacing(2),

      [breakpoints.only('xs')]: {
        top: spacing(14.25),
        left: 'unset',
      },
    },
    '& .Toastify__toast': {
      borderRadius: spacing(0.5),
      padding: spacing(1, 2),
      display: 'flex',
      alignItems: 'center',
      minHeight: 'unset',
      marginBottom: spacing(2),
    },

    '& .Toastify__toast-body': {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: spacing(2),
      },
      '& div:first-child': {
        display: 'none !important',
      },
      '& div:last-child': {
        display: 'flex',
        color: white.main,
      },
    },
    '& .Toastify__toast--success': {
      background: success.main,
    },
    '& .Toastify__close-button': {
      alignSelf: 'unset',
      '& svg': {
        marginLeft: spacing(3),
      },
    },
  },
}));
