import clsx from 'clsx';

import { useStyles } from './Loader.styles';
import { LoaderProps } from './Loader.types';

export const Loader = ({ isMaxSize = true, isFullHeight = false, isFullWidth = false, className }: LoaderProps) => {
  const classes = useStyles({ isMaxSize, isFullHeight, isFullWidth });

  return (
    <div className={clsx(classes.loader, className)}>
      <div className="loader">
        <div />
      </div>
    </div>
  );
};
