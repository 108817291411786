import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AvatarSizes, AvatarStyleProps } from 'ui/atoms/avatar/Avatar.types';
import { getColorByString } from 'ui/atoms/avatar/Avatar.utils';

export const useStyles = makeStyles<Theme, AvatarStyleProps>(({ shadows, spacing, palette: { gray } }) => {
  const getSize = (size: AvatarSizes, smallSize: string, largeSize: string, mediumSize: string) =>
    size === AvatarSizes.small ? smallSize : size === AvatarSizes.large ? largeSize : mediumSize;

  return {
    avatarWrapper: {
      display: 'inline-flex',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'relative',
      marginBottom: ({ label }) => (label ? spacing(2) : 0),
    },
    avatar: {
      '&.MuiAvatar-root': {
        width: ({ size }) => getSize(size, spacing(6), spacing(17.25), spacing(12)),
        height: ({ size }) => getSize(size, spacing(6), spacing(17.25), spacing(12)),
        background: ({ userName }) => (userName ? getColorByString(userName, 30, 80) : gray.main),
        boxShadow: shadows[3],
        fontSize: ({ size }) => getSize(size, spacing(1.5), spacing(6), spacing(2)),
      },
    },
    label: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: spacing(-2),
    },
  };
});
