import { BrowserRouter as Router } from 'react-router-dom';

import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { LocaleContextController } from 'context/locale/localeContextController/LocaleContextController';
import { ReactQueryController } from 'context/locale/reactQueryController/ReactQueryController';
import { Theme } from 'theme/Theme';

import { AppProvidersProps } from './AppProviders.types';

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <Theme>
      <LocaleContextController>
        <Router>
          <ReactQueryController>
            <ClientContextController>{children}</ClientContextController>
          </ReactQueryController>
        </Router>
      </LocaleContextController>
    </Theme>
  );
};
