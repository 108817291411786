import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ palette: { gray }, spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0px',
    gap: '4px',
  },
  detailText: {
    fontWeight: '600 !important',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
  },
  paymentTextField: {
    width: '100%',
  },
  paymentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  mainHeaders: {
    fontFamily: 'Roboto',
    fontWeight: '600 !important',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  btn: {
    marginTop:'100px'
  }
}));
