import Joi from 'joi';

export const schema = Joi.object({
  isClipSelected: Joi.boolean().required(),
  purchaserName: Joi.string().strict().trim().required().messages({
    'string.empty': 'Field is required',
    'string.trim': 'Field is must not have leading or trailing whitespace',
  }),
  email: Joi.string()
    .strict()
    .trim()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'Field is required',
      'string.email': 'Email must be a valid email',
      'string.trim': 'Field is must not have leading or trailing whitespace',
    }),
});

export const defaultValues = {
  isClipSelected: false,
  purchaserName: '',
  email: '',
};
