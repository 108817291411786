import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ spacing }) => ({
  label: {
    margin: 0,

    '& .MuiFormControlLabel-label': {
      marginLeft: spacing(2),
    },
  },
}));
