import { AvatarProps as MaterialAvatarProps } from '@mui/material';
import { ReactNode } from 'react';

export enum AvatarSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export type AvatarProps = MaterialAvatarProps & {
  size: AvatarSizes;
  firstName?: string;
  lastName?: string;
  className?: string;
  fullName?: string;
  label?: ReactNode;
};

export type AvatarStyleProps = AvatarProps & {
  userName?: string;
  label?: ReactNode;
};
