export const getHashedNumberFromString = (text: string) => {
  let number = 0;
  for (let i = 0; i < text.length; i++) {
    number = text.charCodeAt(i) + ((number << 5) - number);
  }
  return number;
};

export const getColorByString = (text: string, s: number, l: number) => {
  const h = getHashedNumberFromString(text) % 360;
  return `hsl(${h},${s}%,${l}%)`;
};
