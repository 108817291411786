import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_CLIPPING_PLATFORM_API_URL,
  headers: {
    'x-portals-access-token': process.env.REACT_APP_CLIPPING_PLATFORM_API_TOKEN || '',
    'x-portals-checkout-token': process.env.REACT_APP_CLIPPING_PLATFORM_API_ACCESS_TOKEN || '',
  },
});

export const activityTrackApi = axios.create({
  baseURL: process.env.REACT_APP_ACTIVITY_TRACKER_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_ACTIVITY_TRACKER_TOKEN || '',
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response);
  },
);

activityTrackApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response);
  },
);
