import { ReactNode, useReducer } from 'react';

import { api } from 'api';

import { ClientDispatchProvider, ClientContextProvider } from '../clientContextProvider/ClientContextProvider';
import { clientReducer } from '../clientState/reducer/clientReducer';

export const ClientContextController = ({ children }: { children: ReactNode }) => {
  const [axiosInstance, dispatch] = useReducer(clientReducer, api);

  return (
    <ClientContextProvider value={axiosInstance}>
      <ClientDispatchProvider value={dispatch}>{children}</ClientDispatchProvider>
    </ClientContextProvider>
  );
};
