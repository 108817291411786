import { AxiosInstance } from 'axios';

export type UpdateCheckout = {
  client: AxiosInstance;
  paymentId: string;
  body: CheckoutBody;
};

type CheckoutBody = {
  productDetails: string;
  transactionDetails?: string;
  participantId: string;
  isGift: boolean;
  email: string;
  receiverEmail?: string | null;
  nonceFromTheClient?: string;
  deviceDataFromTheClient?: string;
};

export type GetCheckoutData = {
  client: AxiosInstance;
  paymentId: string;
};

export type GetRetailDetailsResponse = {
  paymentId: string;
  transactionDetails: string;
  paymentMethod: PaymentMethod;
  retailInfo: RetailInfo;
  taxValue: null | number;
  totalAmount: number;
  email: string;
  currency: string;
  stripeId: string | null;
  paypalId: string | null;
  productDetails: Product[];
  logoUrl:string | null;
};

export enum PaymentMethod {
  StripeUK = 'StripeUK',
  StripeUS = 'StripeUS',
  Paypal = 'Paypal',
}

export type Product = {
  id: string;
  name: string;
  price: number;
  studentFullName?: string;
};

export type RetailInfo = {
  id: string;
  productDetails: Product[];
  address: string;
  isGift: boolean;
  receiverEmail: string | null;
  participantId: string;
  institutionId: string;
  purchaserName: string;
  institutionName?: string;
};

export type CalculateTaxByAvalaraProps = {
  client: AxiosInstance;
  body: CalculateTaxByAvalaraBody;
};

export type CalculateTaxByAvalaraBody = {
  addressCode: AddressCode;
  addressLine1: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  amount: string;
};

export enum AddressCode {
  ToAddress = 'toAddress',
}

export type CalculateTaxByAvalaraResponse = {
  avalaraDocCode: string;
  totalAmount: number;
  totalDiscount: number;
  totalExemption: number;
  totalTaxable: number;
  totalTax: number;
  totalTaxCalculated: number;
  taxDate?: string;
  taxLines: TaxLine[];
  mock: boolean;
  provider: string;
  resultStatus: string;
  messages: string[];
};

export type TaxLine = {
  lineNo: string;
  taxCode: string;
  taxability: boolean;
  taxable: number;
  rate: number;
  tax: number;
  discount: number;
  taxCalculated: number;
  exemption: number;
  taxDetails: TaxDetail[];
};

export type TaxDetail = {
  rate: number;
  tax: number;
  taxable: number;
  country: string;
  region: string;
  jurisType: string;
  jurisName: string;
  taxName: string;
};
