import { AxiosInstance } from 'axios';

import { Action } from 'types';

import { SET_CLIENT } from '../actions/clientActions';

export const clientReducer = (_: AxiosInstance, action: Action): AxiosInstance => {
  switch (action.type) {
    case SET_CLIENT:
      return action.payload;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
