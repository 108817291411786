import { Redirect, Route, Switch } from 'react-router-dom';

import { CheckoutContainer } from 'app/checkout/CheckoutContainer';

import { AppRoute } from './AppRoute.enum';

export const AppRoutes = () => {
  return (
    <Switch>
      <Route path={AppRoute.checkout}>
        <CheckoutContainer />
      </Route>
      <Redirect to={AppRoute.checkout} />
    </Switch>
  );
};
