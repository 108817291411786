import { AxiosInstance } from 'axios';
import { createContext } from 'react';

import { api, activityTrackApi } from 'api';

import { ClientDispatchContextType } from './clientContext.types';

export const ClientContext = createContext<AxiosInstance>(api);
export const ActivityTrackClientContext = createContext<AxiosInstance>(
	activityTrackApi
);
export const ClientDispatchContext = createContext<
	ClientDispatchContextType | undefined
>(undefined);
