import { Typography, TextField } from 'ui/atoms';
import { useStyles } from './StripeInfoCard.styles';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Controller, useForm } from 'react-hook-form';
import { CheckoutFormValue } from './StripeInfoCard.types';
import { defaultValues, schema } from './StripeInfoCard.utils';

import { joiResolver } from '@hookform/resolvers/joi';

interface Props {
  setEmailCheck: (name: string) => void;
  setNameCheck: (name: string) => void;
}

export const StripeInfoCard = ({ setEmailCheck,setNameCheck }: Props) => {
  const styles = useStyles();

  const { formatMessage } = useLocale();

  const { control, errors, watch } = useForm<CheckoutFormValue>({
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { email,purchaserName } = watch();

  if (errors.email) {
    setNameCheck('');
    setEmailCheck('');
  } else {
    setNameCheck(purchaserName);
    setEmailCheck(email);
  }

  return (
    <div className={styles.container}>
      <Typography variant="h5" className={styles.detailText}>
        {formatMessage({ id: 'checkout.payment_page.details' })}
      </Typography>
      <div>
        <Typography className={styles.header} variant="body1">
          Name
        </Typography>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={() => <div className={styles.hidden} />}
          name="isClipSelected"
        />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, value, onBlur }) => (
            <TextField
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              className={styles.paymentTextField}
              variant="outlined"
              placeholder="Enter your name"
              error={!!errors?.purchaserName}
              helperText={errors?.purchaserName?.message}
            />
          )}
          name="purchaserName"
        />
      </div>
      <div>
        <Typography className={styles.header} variant="body1">
          Email address
        </Typography>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, value, onBlur }) => (
            <TextField
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              className={styles.paymentTextField}
              variant="outlined"
              placeholder="Enter your email address"
              error={!!errors?.email}
              helperText={errors?.email?.message}
            />
          )}
          name="email"
        />
      </div>

      {/* <div className={styles.paymentContainer}>
        <Typography className={styles.mainHeaders} variant="body1">
          Is this a gift?
        </Typography>
        <div className={styles.checkboxContainer}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ onChange, value }) => (
              <>
                <FormControlLabel
                  control={
                    <Radio
                      checked={value}
                      onChange={() => {
                        onChange(true);
                        setGiftValue(true);
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={!value}
                      onChange={() => {
                        onChange(false);
                        setGiftValue(false);
                      }}
                    />
                  }
                  label="No"
                />
              </>
            )}
            name="isGiftForGraduate"
          />
        </div>
        {giftValue && (
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ onChange, value, onBlur }) => (
              <TextField
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className={styles.paymentTextField}
                variant="outlined"
                placeholder="Enter graduate's email address"
                error={!!errors?.receiverEmail}
                helperText={errors?.receiverEmail?.message}
              />
            )}
            name="receiverEmail"
          />
        )}
      </div> */}
    </div>
  );
};
