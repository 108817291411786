import { useEffect, useState } from 'react';
import dropin from 'braintree-web-drop-in';
import { useLocale } from 'hooks/useLocale/useLocale';
import { Button, Loader } from 'ui/atoms';

import { useStyles } from './PaypalPayment.styles';
import { PaypalPaymentProps } from './PaypalPayment.types';

export const PaypalPayment = ({
  show,
  handlePaypalPayment,
  retailDetail,
  isPaypalLoading,calculateTax
}: PaypalPaymentProps) => {
  const styles = useStyles();
  const { formatMessage } = useLocale();

  const [braintreeInstance, setBraintreeInstance] = useState<dropin.Dropin | undefined>(undefined);

  useEffect(() => {
    if (show) {
      const initializeBraintree = () =>
        dropin.create(
          {
            authorization: retailDetail.paypalId || '',
            container: '#braintree-drop-in-div',
            card: true,
            preselectVaultedPaymentMethod: false,
            dataCollector: true,
          },
          (error, instance) => {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
          },
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handlePay = () => {
    if (braintreeInstance) {
      braintreeInstance.requestPaymentMethod((error, payload) => {
        if (error) {
          console.error(error);
        } else {
          handlePaypalPayment(payload);
        }
      });
    }
  };

  return (
    <>
      <div style={{ display: `${!isPaypalLoading ? 'block' : 'none'}` }} className={styles.paymentElement}>
        <div id={'braintree-drop-in-div'} />
        {!isPaypalLoading && (
          <Button
            className={styles.payButton}
            disabled={
              !braintreeInstance || !calculateTax
            }
            onClick={handlePay}
          >
            {formatMessage({ id: 'checkout.payment_page.pay_text' })}
          </Button>
        )}
      </div>
      <div style={{ display: `${isPaypalLoading ? 'flex' : 'none'}` }}>
        <Loader isMaxSize={false} isFullHeight={true} isFullWidth={true} />
      </div>
    </>
  );
};
