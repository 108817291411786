import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useGetRetailDetails } from 'api/checkout/checkout';
import { PaymentMethod } from 'api/checkout/checkout.types';
import { useClient } from 'hooks/useClient/useClient';
import { useState } from 'react';
import { Loader } from 'ui/atoms';
import { Checkout } from './Checkout';

const stripeUKPromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');
const stripeUSPromise = loadStripe(process.env.REACT_APP_STRIPE_US_PUBLIC_KEY || '');

export const CheckoutContainer = () => {
  const client = useClient();
  const [isPageLoading, setPageLoading] = useState(false);

  const authResult = new URLSearchParams(window.location.search);
  const paymentId = authResult.get('paymentId');
  const checkoutPageUrl = authResult.get('checkoutPageUrl');

  const { data, isLoading, refetch } = useGetRetailDetails({
    client,
    paymentId: paymentId || '',
    options: {
      enabled: !!paymentId,
    },
  });

  const previousLink = `${document.referrer}${checkoutPageUrl?.substring(1)}`;

  const stripePromise = data?.paymentMethod === PaymentMethod.StripeUK ? stripeUKPromise : stripeUSPromise;

  if (isLoading || isPageLoading) {
    return <Loader isMaxSize />;
  }

  if (!data) {
    return null;
  }

  return (
    <>
      {(data.stripeId || data.paypalId) && (
        <Elements
          options={{ clientSecret: data.stripeId || '', appearance: { theme: 'stripe' } }}
          stripe={stripePromise}
        >
          <Checkout
            previousLink={previousLink}
            retailDetail={data}
            paymentId={paymentId || ''}
            setPageLoading={setPageLoading}
            refetch={refetch}
          />
        </Elements>
      )}
    </>
  );
};
