import { useContext } from 'react';

import { ActivityTrackClientContext } from 'context/client/clientContext/clientContext';

export const useActivityTrackClient = () => {
  const context = useContext(ActivityTrackClientContext);

  if (context === undefined) {
    throw new Error('useClient must be used within an ClientContext');
  }

  return context;
};
