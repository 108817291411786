import { Typography } from 'ui/atoms/index';

import { NotificationMessageProps } from './NotificationMessage.types';
import { notificationIcons } from './NotificationMessage.utils';

export const NotificationMessage = ({ children, type }: NotificationMessageProps) => (
  <>
    {notificationIcons[type]}
    <Typography>{children}</Typography>
  </>
);
