import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

export const MaterialTooltip = withStyles(({ palette: { action } }) => ({
  tooltip: {
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    background: action.hover,
  },
}))(Tooltip);
