import { Typography, TextField } from 'ui/atoms';

import { useLocale } from 'hooks/useLocale/useLocale';
import { Controller, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import { useStyles } from './PaypalInfoCard.styles';
import { CheckoutFormValue, PaypalProps } from './PaypalInfoCard.types';
import { defaultValues, getStateOptionLabel, schema, statesOfUSA } from './PaypalInfoCard.utils';
import { Autocomplete } from '@mui/material';

export const PaypalInfoCard = ({ setEmailCheck, setAddressCheck, setCityCheck, setZipCheck, setRegionCheck, setNameCheck, calculateTax, retailDetail }: PaypalProps) => {
  const styles = useStyles();

  const { formatMessage } = useLocale();

  const { control, errors, watch } = useForm<CheckoutFormValue>({
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const { email, address, purchaserName } = watch();

  const { addressLine1,city,postalCode,region } = JSON.parse(retailDetail.retailInfo.address) as unknown as {
    "addressLine1": string;
    "city": string;
    "region": string;
    "postalCode": string;
    "country": string;
  };

  if (errors.email || errors.address) {
    setEmailCheck('');
    setAddressCheck('');
    setCityCheck('');
    setZipCheck('');
    setNameCheck('')
  } else {
    setEmailCheck(!retailDetail.email ? email : retailDetail.email);
    setNameCheck(!retailDetail.email ? purchaserName : retailDetail.retailInfo.purchaserName);
    setAddressCheck(!retailDetail.email ? address.addressLine1 : addressLine1);
    setCityCheck(!retailDetail.email ? address.city : city);
    setZipCheck(!retailDetail.email ? address.postalCode : postalCode);
    setRegionCheck(address.region);
  }

  return (
    <div className={styles.container}>
      <Typography variant="h5" className={styles.detailText}>
        {formatMessage({ id: 'checkout.payment_page.details' })}
      </Typography>
      <div>
        <Typography className={styles.header} variant="body1">
          Name
        </Typography>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={() => <div className={styles.hidden} />}
          name="isClipSelected"
        />
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, value, onBlur }) => (
            <TextField
              size="small"
              disabled={calculateTax === true || !!retailDetail.email}
              onChange={onChange}
              onBlur={onBlur}
              value={!!retailDetail.email ? retailDetail.retailInfo.purchaserName : value}
              className={styles.paymentTextField}
              variant="outlined"
              placeholder="Enter your name"
              error={!!errors?.purchaserName}
              helperText={errors?.purchaserName?.message}
            />
          )}
          name="purchaserName"
        />
      </div>
      <div>
        <Typography className={styles.header} variant="body1">
          Email address
        </Typography>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, value, onBlur }) => (
            <TextField
              size="small"
              disabled={calculateTax === true || !!retailDetail.email}
              onChange={onChange}
              onBlur={onBlur}
              value={!!retailDetail.email ? retailDetail.email : value}
              className={styles.paymentTextField}
              variant="outlined"
              placeholder="Enter your email address"
              error={!!errors?.email}
              helperText={errors?.email?.message}
            />
          )}
          name="email"
        />
      </div>
      <div className={styles.textFieldContainer}>
        <Typography className={styles.header} variant="body1">
          Address Line 1
        </Typography>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              name="addressLine1"
              disabled={calculateTax === true || !!retailDetail.email}
              onChange={onChange}
              onBlur={onBlur}
              size="small"
              value={!!retailDetail.email ? addressLine1 : value}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              className={styles.paymentTextField}
              variant="outlined"
              placeholder="Address Line 1"
              error={!!errors?.address?.addressLine1}
              helperText={errors?.address?.addressLine1?.message}
            />
          )}
          name="address.addressLine1"
        />
      </div>
      <div className={styles.textFieldContainer}>
        <Typography className={styles.header} variant="body1">
          City
        </Typography>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              size="small"
              disabled={calculateTax === true || !!retailDetail.email}
              onChange={onChange}
              onBlur={onBlur}
              value={!!retailDetail.email ? city : value}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              className={styles.paymentTextField}
              variant="outlined"
              placeholder="City"
              error={!!errors?.address?.city}
              helperText={errors?.address?.city?.message}
            />
          )}
          name="address.city"
        />
      </div>
      <div className={styles.textFieldContainer}>
        <Typography className={styles.header} variant="body1">
          State
        </Typography>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, value }) => (
            <Autocomplete
              options={statesOfUSA}
              className={styles.paymentSelectField}
              disabled={calculateTax === true || !!retailDetail.email}
              size="small"
              onChange={(_, option) => onChange(option?.value)}
              value={!!retailDetail.email ? getStateOptionLabel(region) : getStateOptionLabel(value)}
              getOptionLabel={(option) => option.label.toString()}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  placeholder="State"
                  className={styles.paymentTextField}
                  error={!!errors?.address?.region}
                  helperText={errors?.address?.region?.message}
                  {...params}
                />
              )}
            />
          )}
          name="address.region"
        />
      </div>
      <div className={styles.textFieldContainer}>
        <Typography className={styles.header} variant="body1">
          Zip / Post Code
        </Typography>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ onChange, onBlur, value }) => (
            <TextField
              className={styles.paymentTextField}
              size="small"
              disabled={calculateTax === true || !!retailDetail.email}
              value={!!retailDetail.email ? postalCode : value}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
              onChange={onChange}
              onBlur={onBlur}
              variant="outlined"
              placeholder="Zip/Post code"
              error={!!errors?.address?.postalCode}
              helperText={errors?.address?.postalCode?.message}
            />
          )}
          name="address.postalCode"
        />
      </div>

      {/* <div className={styles.paymentContainer}>
        <Typography className={styles.mainHeaders} variant="body1">
          Is this a gift?
        </Typography>
        <div className={styles.checkboxContainer}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ onChange, value }) => (
              <>
                <FormControlLabel
                  control={
                    <Radio
                      checked={value}
                      onChange={() => {
                        onChange(true);
                        setGiftValue(true);
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={!value}
                      onChange={() => {
                        onChange(false);
                        setGiftValue(false);
                      }}
                    />
                  }
                  label="No"
                />
              </>
            )}
            name="isGiftForGraduate"
          />
        </div>
        {giftValue && (
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ onChange, value, onBlur }) => (
              <TextField
                size="small"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className={styles.paymentTextField}
                variant="outlined"
                placeholder="Enter graduate's email address"
                error={!!errors?.receiverEmail}
                helperText={errors?.receiverEmail?.message}
              />
            )}
            name="receiverEmail"
          />
        )}
      </div> */}
    </div>
  );
};
