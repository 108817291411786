import { AppRoutes } from 'routing/AppRoutes';

import { Notifications } from './notifications/Notifications';

export const App = () => {
  return (
    <>
      <AppRoutes />
      <Notifications />
    </>
  );
};
