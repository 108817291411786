import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LoaderProps } from './Loader.types';

export const useStyles = makeStyles<Theme, LoaderProps>(({ spacing, palette: { primary } }) => ({
  loader: {
    width: ({ isMaxSize, isFullWidth }) => (isMaxSize ? '100vw' : isFullWidth ? '100%' : 'unset'),
    height: ({ isMaxSize, isFullHeight }) => (isMaxSize ? '100vh' : isFullHeight ? '100%' : 'unset'),
    position: ({ isMaxSize }) => (isMaxSize ? 'fixed' : 'unset'),
    zIndex: 99999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .loader': {
      display: 'inline-block',
      transform: 'translateZ(1px)',
    },
    '& .loader > div': {
      display: 'inline-block',
      width: spacing(8),
      height: spacing(8),
      margin: spacing(1),
      borderRadius: '50%',
      background: primary.main,
      animation: 'loader 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite',
    },
  },
}));
