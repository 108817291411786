import { Button, Card, NotificationMessage, Typography } from 'ui/atoms';
import { useStyles } from './Checkout.styles';
import dropin from 'braintree-web-drop-in';
import getSymbolFromCurrency from 'currency-symbol-map';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
// import { ProductCard } from './productCard/ProductCard';
import { CheckoutFormValue, CheckoutProps } from './Checkout.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { ReactComponent as LogoIcon } from 'assets/stage-clip-logo-black-text.svg';
import { KeyboardBackspace } from '@mui/icons-material';
import { useState } from 'react';
import { PaypalPayment } from './paypalPayment/PaypalPayment';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { calculateTaxByAvalara, updatePaymentDetails, useUpdatePaymentDetails } from 'api/checkout/checkout';
import { useClient } from 'hooks/useClient/useClient';
import { useActivityTrack } from 'hooks/useActivityTrack/useActivityTrack';
import { ActivityTypes, CheckoutActivityCode } from 'api/activityTrack/activityTrack.types';
import { AddressCode, PaymentMethod } from 'api/checkout/checkout.types';
import { StripeInfoCard } from './stripeInfoCard/StripeInfoCard';
import { PaypalInfoCard } from './paypalInfoCard/PaypalInfoCard';
import { CircularProgress } from '@mui/material';

export const Checkout = ({ previousLink, retailDetail, paymentId, setPageLoading, refetch }: CheckoutProps) => {
  const client = useClient();
  const styles = useStyles();
  const { onActivityTrack } = useActivityTrack();

  const [nameCheck, setNameCheck] = useState('')
  const [emailCheck, setEmailCheck] = useState(retailDetail.email);
  const [addressCheck, setAddressCheck] = useState('');
  const [cityCheck, setCityCheck] = useState('');
  const [regionCheck, setRegionCheck] = useState('');
  const [zipCheck, setZipCheck] = useState('');

  const { formatMessage } = useLocale();
  const elements = useElements();
  const stripe = useStripe();

  const [isLoading, setLoading] = useState(false);
  const [isPaypalLoading, setPaypalLoading] = useState(false);
  const [calculateTax, setCalculateTax] = useState(!retailDetail.email ? false : true);
  const [isTaxLoading, setIsTaxLoading] = useState(false);
  const [showSalesTax, setShowSalesTax] = useState(!retailDetail.email ? false : true);

  // const [toaltaxValue, setTotaltaxValue] = useState(0)
  // const [totalValue, setTotalValue] = useState(retailDetail.totalAmount)

  const termsUrl = process.env.REACT_APP_TERMS_PAGE_URL;
  const privacyUrl = process.env.REACT_APP_PRIVACY_PAGE_URL;

  const [handleUpdatePaymentDetails, { isLoading: isPaymentUpdateLoading }] = useUpdatePaymentDetails({
    onError: async (data) => {
      refetch();
      if (retailDetail.paymentMethod === PaymentMethod.Paypal) {
        const paypalError: { isApproved: boolean; provider: string; messages: string[] } | undefined = data.data.stack
          ? JSON.parse(data.data.stack)
          : undefined;
        const description = formatMessage(
          { id: 'checkout.payment_page.stripe_error_description' },
          { errorMsg: paypalError?.messages[0] || data.data.message },
        );
        await onActivityTrack(
          ActivityTypes.Checkout,
          CheckoutActivityCode.CheckoutPaymentFailed,
          retailDetail.retailInfo.institutionName || retailDetail.retailInfo.institutionId,
          description,
          retailDetail.email || '',
          retailDetail.retailInfo.participantId,
        );
        toast.error(
          <NotificationMessage type="error">{paypalError?.messages[0] || data.data.message}</NotificationMessage>,
          { autoClose: false },
        );
      }
      setPaypalLoading(false);
      window.onbeforeunload = null;
    },
  });

  const paymentType =
    retailDetail.paymentMethod === PaymentMethod.StripeUS || retailDetail.paymentMethod === PaymentMethod.StripeUK;

  const handleSubmit = async (event: any) => {
    toast.dismiss();
    window.onbeforeunload = () => formatMessage({ id: 'global.actions.dont_close_text' });
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const checkOut = {
      participantId: retailDetail.retailInfo.participantId,
      email: emailCheck,
      receiverEmail: emailCheck,
      isGift: retailDetail.retailInfo.isGift,
      productDetails: JSON.stringify(retailDetail.retailInfo.productDetails),
    }

    await updatePaymentDetails({ client, paymentId, body: checkOut })

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      onErrorPayment(result.error);
      setLoading(false);
    } else {
      onSuccessPayment(result.paymentIntent);
    }
    window.onbeforeunload = null;
  };

  const onErrorPayment = async (StripeError: PaymentIntentResult['error']) => {
    const description = formatMessage(
      { id: 'checkout.payment_page.stripe_error_description' },
      { errorMsg: StripeError?.message },
    );

    await onActivityTrack(
      ActivityTypes.Checkout,
      CheckoutActivityCode.CheckoutPaymentFailed,
      retailDetail.retailInfo.institutionName || retailDetail.retailInfo.institutionId,
      description,
      retailDetail.email || '',
      retailDetail.retailInfo.participantId,
    );
    toast.error(<NotificationMessage type="error">{StripeError?.message}</NotificationMessage>, { autoClose: false });
  };

  const handlePaypalPayment = async (paypal: dropin.PaymentMethodPayload) => {
    toast.dismiss();
    window.onbeforeunload = () => formatMessage({ id: 'global.actions.dont_close_text' });
    setPaypalLoading(true);
    const paypalResponse = await handleUpdatePaymentDetails({
      client,
      body: {
        participantId: retailDetail.retailInfo.participantId,
        email: retailDetail.email,
        receiverEmail: retailDetail.retailInfo.receiverEmail || undefined,
        isGift: retailDetail.retailInfo.isGift,
        productDetails: JSON.stringify(retailDetail.retailInfo.productDetails),
        nonceFromTheClient: paypal.nonce,
        deviceDataFromTheClient: paypal.deviceData,
      },
      paymentId,
    });

    if (paypalResponse) {
      const description = formatMessage({ id: 'checkout.payment_page.stripe_success_description' });
      const initiatedCheckoutData = {
        productDetails: retailDetail.retailInfo.productDetails,
        paymentId,
        isGift: retailDetail.retailInfo.isGift,
      };
      await onActivityTrack(
        ActivityTypes.Checkout,
        CheckoutActivityCode.CheckoutPaymentSuccessful,
        retailDetail.retailInfo.institutionName || retailDetail.retailInfo.institutionId,
        description,
        retailDetail.email,
        retailDetail.retailInfo.participantId,
        JSON.stringify(initiatedCheckoutData),
      );
      window.onbeforeunload = null;

      const url = new URL(previousLink);

      url.searchParams.append('status', 'succeeded');
      url.searchParams.append('paymentId', paymentId);
      window.location.href = `${url}`;
    }
  };

  const onSuccessPayment = async (result: PaymentIntentResult['paymentIntent']) => {
    await handleUpdatePaymentDetails({
      client,
      body: {
        transactionDetails: JSON.stringify(result),
        participantId: retailDetail.retailInfo.participantId,
        email: emailCheck,
        receiverEmail: emailCheck,
        isGift: retailDetail.retailInfo.isGift,
        productDetails: JSON.stringify(retailDetail.retailInfo.productDetails),
      },
      paymentId,
    });

    const description = formatMessage({ id: 'checkout.payment_page.stripe_success_description' });
    const initiatedCheckoutData = {
      paymentDetails: result,
      productDetails: retailDetail.retailInfo.productDetails,
      paymentId,
      isGift: retailDetail.retailInfo.isGift,
    };
    setPageLoading(true);
    const activityResponse = await onActivityTrack(
      ActivityTypes.Checkout,
      CheckoutActivityCode.CheckoutPaymentSuccessful,
      retailDetail.retailInfo.institutionName || retailDetail.retailInfo.institutionId,
      description,
      retailDetail.email,
      retailDetail.retailInfo.participantId,
      JSON.stringify(initiatedCheckoutData),
    );

    if (activityResponse) {
      const url = new URL(previousLink);

      url.searchParams.append('status', result?.status || 'error');
      url.searchParams.append('paymentId', paymentId);
      window.location.href = `${url}`;
    }
  };

  const handleBack = () => {
    setPageLoading(true);
    const url = new URL(previousLink);
    // url.searchParams.append('status', 'patch');
    // url.searchParams.append('paymentId', paymentId);
    window.location.href = `${url}`;
    setPageLoading(false);
  };

  const setTaxValue = async (data: CheckoutFormValue) => {
    setIsTaxLoading(true);

    // if (data.isTaxValueNeed) {
    const {
      totalPrice,
      address: { addressLine1, city, country, postalCode, region },
      isMintAsNFT,
    } = data;

    const taxBody = {
      addressCode: AddressCode.ToAddress,
      addressLine1,
      city,
      region,
      postalCode,
      country,
      amount: `${isMintAsNFT ? retailDetail.totalAmount : totalPrice}`,
    };

    const res = await calculateTaxByAvalara({ client, body: taxBody, });

    // setTotaltaxValue(res.totalTax)
    // setTotalValue(totalPrice)

    const checkOut = {
      participantId: retailDetail.retailInfo.participantId,
      purchaserName: nameCheck,
      email: emailCheck,
      receiverEmail: emailCheck,
      isGift: retailDetail.retailInfo.isGift,
      productDetails: JSON.stringify(retailDetail.retailInfo.productDetails),
      taxValue: res.totalTax,
      totalAmount: ((retailDetail.totalAmount * 100 + res.totalTax * 100) / 100).toFixed(2),
      avalaraDocCode: res.avalaraDocCode,
      address: JSON.stringify({ addressLine1: addressCheck, city: cityCheck, region: region, postalCode: postalCode, country: "USA" })

    }

    await updatePaymentDetails({ client, paymentId, body: checkOut })
    refetch();
    setCalculateTax(true);
    setIsTaxLoading(false);
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <Card className={styles.productDetails}>
        <div className={styles.backButtonContainer}>
          <Button
            variant="text"
            className={styles.backButton}
            disabled={isLoading || isPaypalLoading || isPaymentUpdateLoading}
            onClick={() => handleBack()}
          >
            <KeyboardBackspace />
            {formatMessage({ id: 'global.actions.back' })}
          </Button>
        </div>
        <div className={styles.logoContainer}>
          {!retailDetail.logoUrl ? <LogoIcon /> :
            <div className={styles.instituteLogoContainer}>
              <img
                alt='logo'
                src={retailDetail.logoUrl}
                className={styles.institutelogoTag}
              />
            </div>}
        </div>
        <div className={styles.totalPayContainer}>
          {isTaxLoading ? <CircularProgress className={styles.totalPayContainer} /> : <div>
            <div className={styles.priceContainer}>
              <Typography variant="h6" className={styles.taxPayText}>
                In-person graduation clip
              </Typography>
              <Typography  >
                {`${getSymbolFromCurrency(retailDetail.currency)}${retailDetail.retailInfo.productDetails[0].price.toFixed(2)}`}
              </Typography>
            </div>
            {showSalesTax && !!retailDetail.taxValue && retailDetail.taxValue === 0 ?
              <></>
              : showSalesTax && !!retailDetail.taxValue && retailDetail.taxValue !== 0 ?
              <div className={styles.priceContainer}>
                <Typography variant="h6" className={styles.taxPayText}>
                  Sales Tax
                </Typography>
                <Typography >
                  {!!retailDetail.taxValue && `${getSymbolFromCurrency(retailDetail.currency)}${retailDetail.taxValue.toFixed(2)}`}
                </Typography>
              </div> : ""}

            <div className={styles.priceContainer}>
              <Typography variant="h6" className={styles.totalPayHeader}>
                {formatMessage({ id: 'checkout.payment_page.total_pay' })}
              </Typography>
              <Typography variant="h6" className={styles.totalPayText}>
                {`${getSymbolFromCurrency(retailDetail.currency)}${retailDetail.totalAmount.toFixed(
                  2,
                )} (${retailDetail.currency.toUpperCase()})`}
              </Typography>
            </div>
          </div>}

        </div>
        <div className={styles.productDetailsContainer}>
          {retailDetail.paymentMethod === PaymentMethod.Paypal ? (
            <>
              <PaypalInfoCard
                calculateTax={calculateTax}
                setNameCheck={setNameCheck}
                setEmailCheck={setEmailCheck}
                setAddressCheck={setAddressCheck}
                setCityCheck={setCityCheck}
                setZipCheck={setZipCheck}
                setRegionCheck={setRegionCheck}
                retailDetail={retailDetail}
              />

              {isTaxLoading ? <CircularProgress /> : <Button
                variant="contained"
                type="submit"
                className={styles.taxButton}
                onClick={() => {
                  setTaxValue({
                    address: { addressLine1: addressCheck, city: cityCheck, country: 'USA', postalCode: zipCheck, region: regionCheck },
                    isClipSelected: false,
                    isGiftForGraduate: false,
                    purchaserName: '',
                    email: emailCheck,
                    receiverEmail: emailCheck,
                    totalPrice: retailDetail.totalAmount,
                    products: [],
                    isTaxValueNeed: false,
                    isSecondStep: false,
                    isMintAsNFT: false,
                    isTransferOwnWallet: false
                  })
                  setShowSalesTax(true)
                }}
                disabled={nameCheck === '' || emailCheck === '' || addressCheck === '' || cityCheck === '' || zipCheck === '' || isTaxLoading || calculateTax}
              >
                {formatMessage({ id: 'checkout.paypal_payment_page.pay_text' })}
              </Button>}

              {/* } */}

            </>
          ) : (
            <StripeInfoCard setNameCheck={setNameCheck} setEmailCheck={setEmailCheck} />
          )}
        </div>

        <div className={styles.termsConditionLargeContainer}>
          <Typography variant="body2" className={styles.poweredByTextContainer}>
            {formatMessage({ id: 'checkout.payment_page.powered_by' })}
            <Typography variant="body2" className={styles.stageClipText}>
              {formatMessage({ id: 'checkout.payment_page.stage_clip_text' })}
            </Typography>
          </Typography>
          <div className={styles.divider} />
          <a className={styles.poweredByTextContainer} target="_blank" href={termsUrl} rel="noreferrer">
            {formatMessage({ id: 'checkout.payment_page.terms_text' })}
          </a>
          <a className={styles.poweredByTextContainer} target="_blank" href={privacyUrl} rel="noreferrer">
            {formatMessage({ id: 'checkout.payment_page.privacy_text' })}
          </a>
        </div>
      </Card>
      <Card className={styles.paymentDetails}>
        {paymentType ? (
          <div className={styles.paymentCard}>
            <PaymentElement id="payment-element" className={styles.paymentElement} />
            {/* {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />} */}
            {isLoading ? <CircularProgress /> : <Button
              variant="contained"
              type="submit"
              className={styles.paymentButton}
              disabled={!stripe || isLoading || isPaymentUpdateLoading || emailCheck === '' || nameCheck === ''}
            >
              {formatMessage({ id: 'checkout.payment_page.pay_text' })}
            </Button>}

          </div>
        ) : (
          <div className={styles.paypalCardContainer}>
            <PaypalPayment
              calculateTax={calculateTax}
              show={true}
              handlePaypalPayment={handlePaypalPayment}
              retailDetail={retailDetail}
              isPaypalLoading={isPaypalLoading}
            />
          </div>
        )}
        <div className={styles.termsConditionMobileContainer}>
          <Typography variant="body2" className={styles.poweredByTextContainer}>
            {formatMessage({ id: 'checkout.payment_page.powered_by' })}
            <Typography variant="body2" className={styles.stageClipText}>
              {formatMessage({ id: 'checkout.payment_page.stage_clip_text' })}
            </Typography>
          </Typography>
          <div className={styles.divider} />
          <a className={styles.poweredByTextContainer} target="_blank" href={termsUrl} rel="noreferrer">
            {formatMessage({ id: 'checkout.payment_page.terms_text' })}
          </a>
          <a className={styles.poweredByTextContainer} target="_blank" href={privacyUrl} rel="noreferrer">
            {formatMessage({ id: 'checkout.payment_page.privacy_text' })}
          </a>
        </div>
      </Card>
    </form>
  );
};
