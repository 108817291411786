import { AxiosInstance } from 'axios';
import { QueryConfig } from 'react-query';

export type ActivityCodes = CheckoutActivityCode;

export type ActivityTrack = {
  id: string;
  createdAt: string;
  activityCode: ActivityCodes;
  email: string;
  activityTitle: string;
  application: string;
  channel: string;
  userName: string;
  institution: string;
  data: {
    description: string;
    ipAddress: string;
  } | null;
};

export type PostActivity = {
  activityTrackClient: AxiosInstance;
  body: Activity;
};

export type Activity = {
  activityCode: ActivityCodes;
  email: string | null | undefined;
  channel: string;
  institution: string[] | string | undefined;
  activityType: ActivityTypes;
  userName: string | undefined;
  data: {
    description: string | undefined;
  };
  eventId?: string;
  participantId?: string;
};

export type PostActivityProps = {
  body: PostActivity;
};

export type GetActivityProps = {
  activityTrackClient?: AxiosInstance | undefined;
  institutionName?: string | undefined;
  activityType: ActivityTypes;
  email?: string | undefined;
};

export type GetActivitiesProps = {
  activityTrackClient: AxiosInstance;
  params?: GetParams;
};

export type UseGetActivitiesProps = GetActivitiesProps & {
  options?: QueryConfig<Activities>;
};

export type GetParams = {
  _entity: CommonParams._entity;
  _select: CommonParams._select;
  institution?: string | undefined;
  activityType: ActivityTypes;
  email?: string | undefined;
  eventId?: string;
  participantId?: string;
};

export type Activities = {
  data: ActivityTrack[];
  meta: { page: number; resultCount: number; totalCount: number };
};

export enum CommonParams {
  _entity = 'ActivityTrack',
  _select = '*',
}

export enum ActivityTypes {
  Ceremony = 'CEREMONY',
  Clip = 'CLIP',
  Profile = 'PROFILE',
  Participant = 'PARTICIPANT',
  EventVideo = 'EVENT_VIDEO',
  Survey = 'SURVEY',
  Checkout = 'CHECKOUT',
}

export enum CheckoutActivityCode {
  CheckoutPaymentSuccessful = 'CHECKOUT_PAYMENT_SUCCESSFUL',
  CheckoutPaymentFailed = 'CHECKOUT_PAYMENT_FAILED',
}
