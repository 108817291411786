import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ palette: { black }, breakpoints, spacing }) => ({
  payButton: {
    fontWeight: '500 !important',
    fontSize: '18px !important',
    padding: '10px 0px !important',
    width: '100%',
    marginTop: spacing(2),
  },
  paymentElement: {
    width: '60%',
    padding: '0px 0px 60px 0px',
    [breakpoints.down('md')]: {
      width: '100%',
    },
    "& div[data-braintree-id='toggle']": {
      // display: 'none',
      margin: spacing(2, 0),
    },
  },
}));
