import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ palette, spacing }) => ({
  root: {
    width: spacing(4.5),
    height: spacing(2.5),
    backgroundColor: palette.gray.main,
    padding: 0,
    borderRadius: spacing(1.25),
  },
  switchBase: {
    padding: spacing(0.5),
    '&.Mui-checked': {
      transform: `translateX(${spacing(2)})`,
      '& .MuiSwitch-input': {
        left: spacing(-2),
      },
      '& + .MuiSwitch-track': {
        backgroundColor: palette.primary.main,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: palette.gray.main,
      },
    },
  },
  input: {
    width: spacing(4.5),
    left: 0,
  },
  thumb: {
    width: spacing(1.5),
    height: spacing(1.5),
    left: spacing(0.5),
    backgroundColor: palette.primary.contrastText,
  },
}));
