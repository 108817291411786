import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(({ palette: { white, primary }, spacing }) => ({
  root: {
    height: spacing(1),
    borderRadius: spacing(0.5),
    border: spacing(0.125, 'solid', primary.main),
  },
  colorPrimary: {
    backgroundColor: white.main,
  },
  bar: {
    borderRadius: spacing(0.5),
    backgroundColor: primary.main,
  },
}));
