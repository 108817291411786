import { useContext } from 'react';

import { ClientContext } from 'context/client/clientContext/clientContext';

export const useClient = () => {
  const context = useContext(ClientContext);

  if (context === undefined) {
    throw new Error('useClient must be used within an ClientContext');
  }

  return context;
};
