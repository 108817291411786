import Joi from 'joi';

export const schema = Joi.object({
  isClipSelected: Joi.boolean().required(),
  totalPrice: Joi.number().required().messages({
    'string.empty': 'Field is required',
  }),
  products: Joi.array().items().min(1),
  purchaserName: Joi.string().strict().trim().required().messages({
    'string.empty': 'Field is required',
    'string.trim': 'Field is must not have leading or trailing whitespace',
  }),
  email: Joi.string()
    .strict()
    .trim()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'Field is required',
      'string.email': 'Email must be a valid email',
      'string.trim': 'Field is must not have leading or trailing whitespace',
    }),
  isGiftForGraduate: Joi.boolean().optional(),
  receiverEmail: Joi.string().when('isGiftForGraduate', {
    is: true,
    then: Joi.string()
      .strict()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'string.empty': 'Field is required',
        'string.email': 'Receiver Email must be a valid email',
        'string.trim': 'Field is must not have leading or trailing whitespace',
      }),
    otherwise: Joi.string().optional(),
  }),
  isTaxValueNeed: Joi.boolean().required(),
  isMintAsNFT: Joi.boolean().optional(),
  isTransferOwnWallet: Joi.boolean().when('isMintAsNFT', {
    is: true,
    then: Joi.boolean().required(),
    otherwise: Joi.boolean().optional(),
  }),
  walletAddress: Joi.string().when('isTransferOwnWallet', {
    is: true,
    then: Joi.string().strict().trim().required().messages({
      'string.empty': 'Field is required',
      'string.trim': 'Field is must not have leading or trailing whitespace',
      'any.required': 'Field is required',
    }),
    otherwise: Joi.string().optional(),
  }),

  address: Joi.any().when('email', {
    // is: true,
    then: Joi.object().keys({
      addressLine1: Joi.string().strict().trim().required().messages({
        'string.empty': 'Field is required',
        'string.trim': 'Field is must not have leading or trailing whitespace',
      }),
      city: Joi.string().strict().trim().required().messages({
        'string.empty': 'Field is required',
        'string.trim': 'Field is must not have leading or trailing whitespace',
      }),
      region: Joi.string().strict().trim().required().messages({
        'string.empty': 'Field is required',
        'any.required': 'Field is required',
        'string.trim': 'Field is must not have leading or trailing whitespace',
      }),
      country: Joi.string().strict().trim().required().messages({
        'string.empty': 'Field is required',
        'string.trim': 'Field is must not have leading or trailing whitespace',
      }),
      postalCode: Joi.string()
        .strict()
        .trim()
        .required()
        .pattern(new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/))
        .messages({
          'string.pattern.base': 'Enter valid ZIP/Post code',
          'string.trim': 'Field is must not have leading or trailing whitespace',
          'string.empty': 'Field is required',
        }),
    }),
    otherwise: Joi.any().optional(),
  }),
  isSecondStep: Joi.boolean().required(),
  isAddVoucherCode: Joi.boolean().optional(),
  isVoucherCodeApplied: Joi.boolean().optional(),
  taxValue: Joi.number().optional(),
  voucherCode: Joi.string().strict().trim().optional().messages({
    'string.empty': 'Field is required',
    'string.trim': 'Field is must not have leading or trailing whitespace',
  }),
  avalaraDocCode: Joi.string().optional(),
});

export const defaultValues = {
  isClipSelected: false,
  isGiftForGraduate: false,
  purchaserName: '',
  email: '',
  receiverEmail: '',
  isTaxValueNeed: true,
  address: {
    addressLine1: '',
    city: '',
    country: 'USA',
    postalCode: '',
  },
  isSecondStep: false,
  isAddVoucherCode: false,
  isVoucherCodeApplied: false,
  totalPrice: 0,
  products: [],
  isMintAsNFT: false,
  isTransferOwnWallet: false,
  walletAddress: '',
};

export const statesOfUSA = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];

export const getStateOptionLabel = (value: string) => {
  const stateOption = statesOfUSA.filter((item) => item.value === value)[0];

  return stateOption || { label: '', value };
};
